body {
  #root {
    .custom_checkbox {
      margin-bottom: 28px;
      margin-right: 20px;

      @media screen and (max-width: 670px) {
        margin-bottom: 14px;
        margin-right: 10px;
      }

      .form-check {
        padding-left: 0;
        min-height: 22px;
        display: flex;
        align-items: center;

        .form-check-input {
          margin-top: 0;
          margin-left: 0;
          background-color: rgba(158, 158, 158, 0.2);
          border-radius: 5px;
          width: 22.96px;
          height: 22px;
          border: none !important;

          @media screen and (max-width: 670px) {
            width: 15px;
            height: 15px;
            padding-bottom: 10px;
          }

          &:checked[type="checkbox"] {
            background-image: url("https://i.ibb.co/FBWT9w9/checkbox.png");

            &::before {
              width: 20px;
              height: 20px;
              background-color: #ff3400;
            }
          }

          &:focus {
            box-shadow: none;
          }
        }

        .form-check-label {
          margin-left: 9px;
          font-weight: 300;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #ffffff;

          @media screen and (max-width: 670px) {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
  }
}
