.voting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  justify-content: center;
  //margin-top: -50px;
}
.vote-options-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the space between cards */
  width: 90%;
  height: 50%;
  padding: 0px;

  //max-width: 75%;
}
.voteCounter {
  display: flex;
  width: 85%;
  justify-content: right;
  font-weight: 500;
}
.voteCounterNumber {
  display: flex;
  margin-left: 5px;
  justify-content: right;
  font-weight: 800;
  color: #ff3400 !important;
}

.vote-option-card {
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(33.333% - 20px); /* Example for 3 cards per row, adjust as necessary */
  box-shadow: 0 4px 8px rgba(34, 33, 33, 0.897); /* Simple shadow for depth */
  border-radius: 10px; /* Rounded corners */
  padding: 10px;

  //width: 400px;
  box-sizing: border-box;
  background-color: black;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex: 1 0 calc(100% - 40px);
    max-width: 325px;
  }
}

.vote-option-card h3 {
  font-size: 1.5em;
  text-wrap: nowrap;
  margin-top: 0;
}

.vote-option-card p {
  margin-bottom: 0;
}

.optionTitle {
  text-align: center;
  font-size: 1.5em;
  @media screen and (max-width: 500px) {
    font-size: 1.25em;
  }
}
.optionImg {
  max-width: 400px;
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
}
.optionSubtitle {
  font-size: 1em;
  color: #ff3400 !important;
  font-weight: 800;
  //min-height: 65px;
  max-width: 350px;
  text-align: center;
  align-content: center;
  @media screen and (max-width: 500px) {
    max-width: 300px;
    font-size: 0.75em;
  }
}
.optionDesc {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines you want to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-top: 10px;
  text-align: left;
  max-width: 350px;
  font-size: 1em;
  max-height: 150px;
  @media screen and (max-width: 500px) {
    max-width: 300px;
    font-size: 0.65em;
  }
}
.votesCount {
  text-align: center;
  font-size: 1.75em;
  @media screen and (max-width: 500px) {
    font-size: 1.25em;
  }
}
.vote-button {
  min-width: 150px;
  height: 38px;
  color: white;
  border-radius: 4px;
  border: 2px solid #ff3400 !important;
  background-color: transparent !important;
  padding: 0 5px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 5px;

  @media screen and (max-width: 1500px) {
    flex-direction: row;
    align-items: flex-start;
    min-width: 120px;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    margin: 5px;
  }
}
.divider {
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 0.5px;
  border-bottom: 2px solid #383534;
  width: 350px;
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }

  .details-expand {
    color: #ff3400 !important;
  }
}
