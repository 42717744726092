body {
  #root {
    .tag {
      padding: 5px;
      background: #FF3400;
      border: 3px solid #FF3400;
      border-radius: 4px;
      font-weight: 700;
      font-size: 20px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}
