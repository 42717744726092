body {
  .item-detail-modal-content {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 575px) {
      flex-direction: column;
    }

    .left-container {
      width: 43%;
      height: 600px;
      max-height: 600px;
      overflow: hidden;
      padding: 35px 0 35px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 3px solid #202020;

      @media screen and (max-width: 991px) {
        padding: 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 20px;
      }

      @media screen and (max-width: 575px) {
        height: 100%;
        padding: 10px;
        width: 100%;
        border-right: none;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .image-container {
        width: 100%;
        max-width: 350px;
        height: 350px;
        background: #000000;
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        @media screen and (max-width: 991px) {
          max-width: 270px;
          height: 270px;
        }

        @media screen and (max-width: 767px) {
          max-width: 210px;
          height: 210px;
        }

        @media screen and (max-width: 575px) {
          max-width: 150px;
          min-width: 150px;
          height: 150px;
          margin-right: 10px;
        }

        .tag {
          position: absolute;
          top: 10px;
          left: 5px;
          padding: 1px 7px;
          background: rgba(255, 255, 255, 0.12);
          border-radius: 4px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;

          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }

          @media screen and (max-width: 575px) {
            font-size: 10px;
            line-height: normal;
            padding: 2px 4px;
          }
        }

        .primary-button {
          min-width: 99px;
          position: absolute;
          bottom: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .item-info {
        @media screen and (max-width: 575px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .description-title {
          margin-top: 20px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          width: 100%;
          max-width: 350px;

          @media screen and (max-width: 991px) {
            font-size: 13px;
            line-height: normal;
            max-width: 270px;
          }

          @media screen and (max-width: 767px) {
            font-size: 12px;
            max-width: 210px;
          }

          @media screen and (max-width: 575px) {
            margin-top: 0;
            font-size: 10px;
            max-width: 100%;
          }
        }

        .description-text {
          margin-top: 8px;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.8px;
          max-width: 350px;
          max-height: 125px;
          overflow: auto;

          @media screen and (max-width: 991px) {
            font-size: 13px;
            line-height: normal;
            max-width: 270px;
            max-height: 200px;
            overflow: auto;
          }

          @media screen and (max-width: 767px) {
            font-size: 12px;
            max-width: 210px;
            max-height: 250px;
            overflow: auto;
          }

          @media screen and (max-width: 575px) {
            font-size: 10px;
            max-width: fit-content;
            padding-right: 10px;
            max-height: 125px;
            overflow: auto;
          }
        }
      }
    }

    .right-container {
      padding: 35px 43px 25px 0;
      width: 57%;
      max-height: 600px;

      @media screen and (max-width: 991px) {
        padding: 30px 20px 30px 20px;
      }

      @media screen and (max-width: 767px) {
        padding: 20px 10px 20px 10px;
      }

      @media screen and (max-width: 575px) {
        padding: 20px 10px 0 10px;
        width: 100%;
        max-height: 100%;
      }

      .sections {
        width: 100%;
        height: 100%;
        max-height: 600px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 35px 0 35px;
        display: flex;
        flex-direction: column;
        justify-content: normal;

        @media screen and (max-width: 991px) {
          padding: 0 10px 0 10px;
        }

        @media screen and (max-width: 991px) {
          padding: 0 10px 0 10px;
        }

        @media screen and (max-width: 575px) {
          max-height: 100%;
        }

        .modal-section {
          @media screen and (max-width: 575px) {
            margin-bottom: 10px !important;
          }

          .section-header {
            padding: 6px 0;
            margin-bottom: 23px;

            .title {
              margin-left: 8px;
            }
          }

          .section-content {
            .stats-items-container {
              .stats-item {
                width: 100%;
                height: 86px;
                background: rgba(158, 158, 158, 0.2);
                border-radius: 10px;
                margin-bottom: 23px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (max-width: 767px) {
                  height: 70px;
                }

                @media screen and (max-width: 575px) {
                  margin-bottom: 15px;
                }

                .title {
                  font-weight: 800;
                  font-size: 17px;
                  line-height: 24px;
                  text-align: center;
                  margin-bottom: 0px;

                  @media screen and (max-width: 767px) {
                    font-size: 13px;
                  }
                }

                .subtitle {
                  font-weight: 800;
                  font-size: 17px;
                  line-height: 24px;
                  text-align: center;
                  margin-bottom: 0px;

                  @media screen and (max-width: 767px) {
                    font-size: 15px;
                    margin-bottom: 0px;
                  }
                }
              }
            }

            .metadata-items-container {
              .col-6 {
                margin-top: 10px;
                margin-bottom: 10px;
              }
              .metadata-item {
                width: 100%;
                height: auto;
                padding: 10px;
                background: rgba(158, 158, 158, 0.2);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (max-width: 767px) {
                  height: 100%;
                }

                @media screen and (max-width: 575px) {
                }

                .title {
                  font-weight: 700;
                  font-size: clamp(9px, 1vw, 14px);
                  line-height: 18px;
                  text-transform: uppercase;
                  margin-bottom: 0px;

                  @media screen and (max-width: 767px) {
                    //  font-size: 13px;
                  }
                  @media screen and (max-width: 575px) {
                    //font-size: 9px;;
                  }
                }

                .sub-text {
                  font-weight: 300;
                  font-size: 13px;
                  line-height: 16px;
                  color: #ddd;

                  @media screen and (max-width: 767px) {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .lootButtons {
    display: flex;
    padding-top: 10px;
    max-width: 350px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      order: 3;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      flex-direction: column;
      order: 3;
    }
    .primary-button {
      min-width: 150px;
    }
    .secondary-button {
      min-width: 150px;
    }
  }
}
