body {
  .item-open-modal {
    @media screen and (max-width: 991px) {
      padding: 25px 29px;
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 20px 24px;
    }

    @media screen and (max-width: 575px) {
      padding: 15px 19px;
    }

    .image-container {
      width: 550px;
      height: 550px;
      background: #2e2e2e;
      border-radius: 16px;

      @media screen and (max-width: 991px) {
        align-self: center;
        width: 425px;
        height: 425px;
      }

      @media screen and (max-width: 767px) {
        width: 300px;
        height: 300px;
      }

      @media screen and (max-width: 575px) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text-container {
      @media screen and (max-width: 991px) {
        max-width: 425px;
      }

      @media screen and (max-width: 767px) {
        max-width: 300px;
      }

      @media screen and (max-width: 575px) {
        max-width: 200px;
      }

      .title {
        font-weight: 700;
        font-size: 64px;
        line-height: 77px;
        margin-bottom: 0;
        text-transform: uppercase;

        @media screen and (max-width: 991px) {
          font-size: 50px;
          line-height: normal;
        }

        @media screen and (max-width: 767px) {
          font-size: 35px;
        }

        @media screen and (max-width: 575px) {
          font-size: 25px;
        }
      }

      .description {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        margin-left: 10px;
        margin-bottom: 0;

        @media screen and (max-width: 991px) {
          font-size: 20px;
          line-height: normal;
          margin-left: 5px;
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
          margin-left: 0;
        }

        @media screen and (max-width: 575px) {
          font-size: 12px;
        }
      }

      &.claimed {
        text-align: center;

        .description {
          margin-left: 0;
        }

        .primary-button {
          margin-top: 10px;
          min-width: 99px;
        }
      }
    }
  }
}
