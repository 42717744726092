body {
  #root {
    .announcement-card {
      background: rgba(36, 38, 38, 0.47);
      border-radius: 40px;
      padding: 32px;
      padding-left: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;


      @media screen and (max-width: 1500px) {
        padding-left: 32px;
      }

      @media screen and (max-width: 575px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 15px;
      }

      .card-content {
        @media screen and (max-width: 575px) {
          display: flex;
          flex-direction: column;
          align-items: center;

         
        }

        .title {
          font-weight: 700;
          font-size: 65px;
          line-height: 93px;
          color: #FFFFFF;
          margin-bottom: 0px;
          margin-right: 100px;

          @media screen and (max-width: 1500px) {
            font-size: 60px;
            margin-bottom: 15px;
            line-height: normal;
          }

          @media screen and (max-width: 1200px) {
            font-size: 45px;
            margin-bottom: 10px;
          }

          @media screen and (max-width: 767px) {
            font-size: 35px;
            margin: 10px 0;
          }
        }

        .subtitle {
            font-weight: 700;
            font-size: 24px;
            line-height: 20px;
            color: #d64823;
            margin-bottom: 35px;
            max-width: 85%;

          @media screen and (max-width: 1500px) {
            font-size: 25px;
            margin-bottom: 45px;
            line-height: normal;
          }

          @media screen and (max-width: 1200px) {
            font-size: 17px;
            margin-bottom: 35px;
          }

          @media screen and (max-width: 767px) {
            font-size: 15px;
            margin-bottom: 10px;
          }
        }

        .primary-button {
          @media screen and (max-width: 575px) {
            align-self: center;
          }
        }

        .paragraph {
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          max-width: 377px;
          margin-bottom: 70px;
          height: 125px;
          overflow: auto;

          @media screen and (max-width: 1500px) {
            font-size: 14px;
            margin-bottom: 50px;
            max-width: 300px;
            line-height: normal;
          }

          @media screen and (max-width: 1200px) {
            font-size: 13px;
            margin-bottom: 40px;
            max-width: 250px;
            padding-right: 10px;
          }

          @media screen and (max-width: 767px) {
            font-size: 11px;
            margin-bottom: 35px;
            max-width: 235px;
          }

          @media screen and (max-width: 575px) {
            text-align: center;
            margin-bottom: 30px;
            padding-right: 0;
          }
        }
      }

      .card-image-container {
        width: 435px;
        height: 435px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 45px;


        @media screen and (max-width: 1500px) {
          width: 335px;
          height: 335px;
        }

        @media screen and (max-width: 1200px) {
          width: 235px;
          height: 235px;
        }

        @media screen and (max-width: 767px) {
          width: 135px;
          height: 135px;
        }
      }
    }
  }
}