body {
  #root {
    .lootboxes-page {
      margin-top: 25px;

      .content {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        .lootbox-filters-container {
          min-width: 260px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: #0f0f0f;
          padding-top: 10px;

          @media screen and (max-width: 1200px) {
            padding: 0;
            padding-top: 10px;
          }

          .lootbox-filters-header {
            margin-bottom: 29px;

            .title {
              font-weight: 400;
              font-size: 24px;
              line-height: 16px;
              letter-spacing: 2px;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 0;
              margin-left: 12px;
            }
            .refresh {
              padding-right: 5px;
              padding-left: 5px;
            }
          }

          .filters {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            @media screen and (max-width: 1200px) {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: center;
            }

            .custom_checkbox {
              @media screen and (max-width: 1200px) {
                margin-right: 20px;
              }
            }
          }
        }

        .left-container {
          min-width: 260px;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: #0f0f0f;
          padding-top: 10px;

          @media screen and (max-width: 1200px) {
            padding: 0;
            padding-top: 10px;
          }

          .header {
            margin-bottom: 29px;

            .title {
              font-weight: 400;
              font-size: 24px;
              line-height: 16px;
              letter-spacing: 2px;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 0;
              margin-left: 12px;
            }
          }
        }
        .filters {
          display: flex;
        }
        .lootboxes {
          margin-top: 10px;
          border-top: 3px solid #202020;
          padding: 0px 20px;
          width: 100%;
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(auto-fit, minmax(300px, 300px));

          @media screen and (max-width: 925px) {
            grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
          }

          @media screen and (max-width: 670px) {
            grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
          }

          @media screen and (max-width: 1700px) {
            padding: 20px;
          }

          .lootboxes-slider {
            .nft-card {
              margin-bottom: 20px;
            }

            .slick-list {
              margin: 0 -5px;
            }

            .slick-slide {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
