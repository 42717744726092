body {
  #root {
    .nft-card {
      background: rgba(36, 38, 38, 0.47);
      border-radius: 16px;
      padding: 5px;
      padding-bottom: 10px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 269px;
      overflow: hidden;
      flex: 0 1 100%;

      @media screen and (max-width: 670px) {
      }

      .img-size {
        height: 250px;
        width: 250px;

        @media screen and (max-width: 925px) {
          height: 175px;
          width: 175px;
        }

        @media screen and (max-width: 670px) {
          height: 100px;
          width: 100px;
        }
      }

      .image-container {
        height: 250px;
        width: 100%;
        max-width: 250px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        margin-bottom: 26px;
        overflow: hidden;

        @media screen and (max-width: 925px) {
          height: 175px;
          width: 175px;
          margin-bottom: 15px;
        }

        @media screen and (max-width: 670px) {
          height: 100px;
          width: 100px;
          margin-bottom: 10px;
        }
      }

      .nft-info {
        display: flex;
        align-items: center;
        width: 100%;

        .title {
          font-weight: 500;
          width: 100%;
          font-size: clamp(8px, 1.6vw, 18px);
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;

          @media screen and (max-width: 670px) {
            //font-size: 14px;
            margin-right: 0px;
            padding-left: 0px;
            text-align: center;
            white-space: nowrap;
            line-height: 18px;
            justify-content: space-between;
          }
        }

        .true {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .false {
          font-weight: 500;
          font-size: 20px;
          background-color: red;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .primary-button {
          min-width: 99px;
        }
      }
    }
  }
}
