body {
  #root {
    .offers-page {
      margin-top: 75px;

      .main-section {
        .section-header {
          padding-top: 0;
        }

        &.unclaimed-offers {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .box {
            min-width: 243px;
            min-height: 71px;
            background: rgba(36, 38, 38, 0.47);
            border-radius: 10px;
          }
        }

        .offer-card-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        &.active-claims {
          .section-content {
            .price-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 500px;
              margin-left: 100px;

              .price {
                min-width: 190px;
                height: 38px;
                border-radius: 4px;
                border: 2px solid #2c2c2c;
                background-color: transparent;
                padding: 0;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.section-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  .claimings {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    justify-content: center;
    @media screen and (max-width: 925px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    }

    @media screen and (max-width: 670px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
    }

    @media screen and (max-width: 1700px) {
      padding: 20px;
    }
  }
}
