body {
  #root {
    .active-claims {
      .header {
        border-bottom: 3px solid #202020;
        padding-bottom: 17px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .title {
          font-weight: 400;
          font-size: 24px;
          line-height: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #FFFFFF;
          margin-bottom: 0;
          margin-left: 15px;
        }
      }
    }
  }
}