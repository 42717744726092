.flex-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Adjust as needed */
}

/* Default to 3 items per line */
.flex-grid > * {
  flex: 1 0 calc(33.333% - 1rem); /* For 3 items per row */
}

/* For screens between 575px and 1200px, show 2 items per line */
@media (max-width: 1200px) and (min-width: 576px) {
  .flex-grid > * {
    flex: 1 0 calc(50% - 1rem); /* For 2 items per row */
  }
}

/* For screens smaller than 575px, show 1 item per line */
@media (max-width: 575px) {
  .flex-grid > * {
    flex: 1 0 100%; /* For 1 item per row */
  }
}
