body {
  #root {
    .store-card {
      background: rgba(36, 38, 38, 0.47);
      border-radius: 16px;
      margin: 20px;
      display: flex;
      flex-direction: row;
      width: 75%;
      justify-content: space-between;

      @media screen and (max-width: 670px) {
        display: flex;
        flex-direction: row;
        width: 85%;
        margin: 10px;
        align-items: center;
      }

      .img-size {
        height: 100px;
        width: 100px;

        @media screen and (max-width: 925px) {
          height: 100px;
          width: 100px;
        }

        @media screen and (max-width: 670px) {
          height: 75px;
          width: 75px;
        }
      }

      .image-container {
        display: flex;
        height: 100px;
        width: 25%;
        max-width: 100px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        margin: 10px;
        overflow: hidden;

        @media screen and (max-width: 925px) {
          height: 100px;
          width: 100px;
          margin-bottom: 15px;
        }

        @media screen and (max-width: 670px) {
          height: 75px;
          width: 75px;
          margin-bottom: 10px;
        }
      }

      .store-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;
        justify-content: center;
        @media screen and (max-width: 670px) {
          max-width: 100%;
        }

        .title {
          font-weight: 500;
          width: 100%;
          font-size: clamp(8px, 1.6vw, 18px);
          line-height: 24px;
          color: #ffffff;
          //margin-top: 10px;
          margin-bottom: 0px;
          //margin-right: 20px;
          //padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;

          @media screen and (max-width: 1250px) {
            font-size: 14px;
            margin-right: 0px;
            padding-left: 0px;
            text-align: center;
            white-space: nowrap;
            line-height: 18px;
            justify-content: space-between;
          }

          @media screen and (max-width: 670px) {
            //font-size: 14px;
            font-size: clamp(9px, 1.2vw, 16px);
            margin-right: 0px;
            padding-left: 0px;
            text-align: center;
            white-space: nowrap;
            line-height: 18px;
            justify-content: space-between;
          }
        }
        .price {
          font-weight: 500;
          width: 100%;
          font-size: clamp(8px, 1.6vw, 18px);
          line-height: 24px;
          color: #ffffff;
          //margin-top: 10px;
          margin-bottom: 0px;
          //margin-right: 20px;
          //padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;

          @media screen and (max-width: 1250px) {
            font-size: 14px;
            margin-right: 0px;
            padding-left: 0px;
            text-align: center;
            white-space: nowrap;
            line-height: 18px;
            justify-content: space-between;
          }
          @media screen and (max-width: 670px) {
            font-size: clamp(9px, 1.2vw, 16px);
            margin-right: 0px;
            padding-left: 0px;
            text-align: center;
            white-space: nowrap;
            line-height: 18px;
            justify-content: space-between;
          }
        }

        .true {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .false {
          font-weight: 500;
          font-size: 20px;
          background-color: red;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .primary-button {
          min-width: 99px;
        }
        .button-div {
          display: flex;
          width: 33%;
        }
      }
    }
    .button-div {
      display: flex;
      width: 25%;
      align-items: center;
      justify-content: center;
      margin-right: 5px;

      .mint-button {
        display: flex;
        flex-direction: column;
        height: 38px;
        border-radius: 4px;
        border: 2px solid #ff3400 !important;
        background-color: transparent !important;
        padding: 0 15px;
        text-transform: uppercase;
        color: white;
        font-weight: 300;
        justify-content: center;

        @media screen and (max-width: 1250px) {
          height: 28px;
          font-size: 12px;
        }
        @media screen and (max-width: 670px) {
          height: 22px;
          font-size: 9px;
          align-items: center;
          border: 1px solid #ff3400 !important;
        }
      }
    }
  }
}
