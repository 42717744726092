body {
  #root {
    footer {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin-top: 300px;
      padding: 45px 0;

      @media screen and (max-width: 1500px) {
        flex-direction: column;
      }

      .footer-logo {
        width: 300px;
        height: 97px;
        margin-top: -50px;

        @media screen and (max-width: 1500px) {
          margin-top: 0;
        }
      }

      ul {
        margin-bottom: 41px;

        @media screen and (max-width: 1200px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
        }

        &.links {
          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
            margin-right: 46px;

            @media screen and (max-width: 1200px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }

      .copyright-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
  }
}