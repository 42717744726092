.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.vote-details-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191a1a;
  //padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 50%;
  text-align: center;
  @media screen and (max-width: 870px) {
    max-width: 75%;
  }
  .vote-details-banner {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .vote-details-title {
    color: white !important;
    margin-top: 15px;
    font-size: 1.5em;
    //color: #ff3400 !important ;\
    @media screen and (max-width: 870px) {
      font-size: 1em;
    }
  }
  .vote-details-desc {
    color: rgb(180, 174, 174) !important;
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
    @media screen and (max-width: 870px) {
      font-size: 0.75em;
    }
  }
  .store-loader {
    margin: 10px;
  }

  .vote-details-infoTitle {
    font-size: 0.8em;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    align-self: flex-start;
    @media screen and (max-width: 870px) {
      font-size: 0.65em;
    }
  }
  .highlight {
    color: #ff9900;
    font-style: italic;
    font-weight: 800;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0056b3;
    }
  }
}
