body {
  #root,
  .view-modal {
    // CUSTOM SCROLLBAR
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #202020;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: #ff3400;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(255, 52, 0, 0.5);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
      color: #fff;
    }

    .primary-button {
      min-width: 190px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid #ff3400 !important;
      background-color: transparent !important;
      padding: 0 5px;
      text-transform: uppercase;
      font-weight: 300;
      margin: 5px;

      @media screen and (max-width: 1500px) {
        flex-direction: row;
        align-items: flex-start;
        min-width: 120px;
      }

      @media screen and (max-width: 767px) {
        align-items: center;
        margin: 5px;
      }
      @media screen and (max-width: 670px) {
        align-items: center;
        min-width: 90px;
        margin: 5px;
      }
    }

    .primary-button-two {
      min-width: 190px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid #ff3400 !important;
      background-color: transparent !important;
      padding: 0 5px;
      text-transform: uppercase;
      font-weight: 300;
      margin: 5px;

      @media screen and (max-width: 1500px) {
        flex-direction: row;
        align-items: flex-start;
        min-width: 120px;
      }

      @media screen and (max-width: 767px) {
        align-items: center;
        margin: 5px;
      }
    }

    .secondary-button {
      min-width: 190px;
      height: 38px;
      border-radius: 4px;
      border: 2px solid #2c2c2c;
      background-color: transparent !important;
      padding: 0;
      text-transform: uppercase;
      margin: 5px;

      @media screen and (max-width: 1500px) {
        flex-direction: row;
        align-items: flex-start;
        min-width: 120px;
      }

      @media screen and (max-width: 767px) {
        align-items: center;
        margin: 5px;
      }
    }
  }
}
