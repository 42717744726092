body {
  .item-open-modal {
    .image-container {
      width: 550px;
      height: 550px;
      background: #2e2e2e;
      border-radius: 16px;
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .description {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-left: 10px;
      margin-bottom: 0;
    }

    .claimed {
      text-align: center;
      padding: 15px;

      .description {
        margin-left: 0;
      }

      .primary-button {
        margin-top: 10px;
        min-width: 99px;
      }
    }
  }
}
