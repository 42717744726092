.nav-list-item {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding-left: 75px;
  margin-bottom: 55px;
  cursor: pointer;
  text-decoration: none;
  /* For Safari 3.0 to 6.0 */
  -webkit-transition: color 0.5s, border-color 0.5s;
  /* For modern browsers */
  transition: color 0.5s, border-color 0.5s;
  margin-right: -3px;
  border-right: 3px solid transparent;

  .nav-list-item-title {
    color: #fff;
    -webkit-transition: color 0.5s, border-color 0.5s;
    transition: color 0.5s, border-color 0.5s;
  }

  &.active {
    color: #FF3400 !important;

    .nav-list-item-title {
      color: #FF3400 !important;
    }
  }

  &:hover {
    color: #FF3400 !important;

    .nav-list-item-title {
      color: #FF3400 !important;
    }
  }

  &.double-space {
    margin-bottom: 110px;
  }

  svg {
    margin-right: 30px;
    width: 30px;
    font-size: 20px;
  }
}

.invis {
  display: none;
}