body {
  .main-section {
    .section-header {
      border-bottom: 3px solid #202020;
      margin-bottom: 30px;
      padding: 16px 0;

      .title {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }
}
