body {
  #root {
    .grid-card-two {
      background: #191A1A;
      border-radius: 45px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: auto;


      @media screen and (max-width: 767px) {
        padding: 15px;
      }

      .image-container {
        width: 250px;
        height: 250px;
        background: #101010;
        border-radius: 45px;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
          width: 200px;
          height: 200px;
        }
      }

      .title {
        font-weight: 700;
        font-size: 65px;
        line-height: 93px;
        color: #FFFFFF;
        margin-bottom: 0px;
        text-align: center;

        @media screen and (max-width: 1200px) {
          font-size: 65px;
          margin-bottom: 15px;
          line-height: normal;
        }

        @media screen and (max-width: 767px) {
          font-size: 55px;
          margin-bottom: 25px;
        }
      }

      .subtitle {
        font-weight: 300;
        font-size: 20px;
        line-height: 20px;
        color: #d64823;
        margin-bottom: 0px;
        text-align: center;
        }

      .screenName {
        font-weight: 700;
        font-size: 20px;
        line-height: 39px;
        color: #FFFFFF;
        margin-bottom: 16px;
        text-align: center;

        @media screen and (max-width: 1200px) {
          font-size: 24px;
          margin-bottom: 25px;
          line-height: normal;
        }
      }

      .description {
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        max-width: 85%;
        height: auto;
        overflow: auto;

        @media screen and (max-width: 1200px) {
          font-size: 14px;
          line-height: normal;
          max-width: 85%;
        }

        @media screen and (max-width: 767px) {
          font-size: 13px;
          max-width: 85%;
        }
      }

      .socials {
        margin-top: 25px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}