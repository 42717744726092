body {
  #root {
    .carousel-slider {
      &.single-row {
        .slick-list {
          margin: 0 -35px;

          .slick-track {
            height: 100%;
            

            .slick-slide {
              padding: 0 35px;

              &>div {
                display: flex;
                height: 100%;
                justify-content: center;
              }
            }
          }
        }
      }

      .slick-slider {
        overflow: hidden;
        display: grid;
        padding-bottom: 100px;

        .arrow {
          width: 55px;
          height: 61px;
          background: rgba(158, 158, 158, 0.2);
          -webkit-backdrop-filter: blur(11.5px);
          backdrop-filter: blur(11.5px);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 1;

          img {
            pointer-events: none;
          }

          &.arrow-right {
            position: absolute;
            right: 0;
            bottom: 0;
          }

          &.arrow-left {
            position: absolute;
            right: 280px;
            bottom: 0;

            @media screen and (max-width: 767px) {
              right: auto;
              left: 0;
            }
          }
        }

        .slick-list {
          .slick-track {
            .slick-slide {
              .nft-card {
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
        }

        .slick-dots {
          display: flex !important;
          align-items: center;
          justify-content: center;
          bottom: 26px;
          right: 65px;
          width: auto;

          @media screen and (max-width: 767px) {
            display: none !important;
          }

          li {
            width: 41px;
            height: 5px;

            button {
              width: 41px;
              height: 5px;
              padding: 0;
              width: 41px;
              height: 5px;
              background-color: #191A1A;
              opacity: 1;

              &::before {
                content: none;
              }
            }

            &.slick-active {
              button {
                width: 41px;
                height: 5px;
                background-color: #FF3400;

                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }

      &.center-buttons {
        .slick-slider {
          .arrow {
            &.arrow-left {
              right: auto;
              left: 0;
            }
          }

          .slick-dots {
            right: auto;
            left: auto;
            width: 100%;

            @media screen and (max-width: 767px) {
              display: none !important;
            }
          }
        }
      }
    }
  }
}