body {
  .view-modal {
    padding: 0 !important;
    background: rgba(128, 128, 128, 0.38);

    .modal-dialog {
      &.modal-lg {
        max-width: 970px;
      }

      &.modal-md {
        max-width: 632px;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-content {
          width: auto;
          align-self: center;
          padding: 15px;
        }
      }

      .modal-content {
        background: #191a1a;
        border-radius: 16px;
        color: #fff;
        border: none;
      }
    }
  }
}
