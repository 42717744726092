body {
  #root {
    .store-page {
      display: flex;
      flex-direction: column;
      margin-top: 75px;
      width: 100%;
      align-items: center;

      @media screen and (max-width: 670px) {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        align-items: center;
      }

      .main-section {
        .section-header {
          padding-top: 0;
        }

        &.unclaimed-offers {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .box {
            min-width: 243px;
            min-height: 71px;
            background: rgba(36, 38, 38, 0.47);
            border-radius: 10px;
          }
        }

        &.active-claims {
          .section-content {
            .price-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 500px;
              margin-left: 100px;

              .price {
                min-width: 190px;
                height: 38px;
                border-radius: 4px;
                border: 2px solid #2c2c2c;
                background-color: transparent;
                padding: 0;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
