body {
  .item-open-modal {
    .image-container {
      width: 550px;
      height: 550px;
      background: #2e2e2e;
      border-radius: 16px;

      @media (max-width: 670px) {
        display: flex;
        width: 200px;
        height: 200px;
      }
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 77px;
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;

      @media screen and (max-width: 670px) {
        font-size: 20px;
        line-height: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-left: 10px;
      margin-bottom: 0;
      text-align: center;
    }

    .claimed {
      text-align: center;
      padding: 15px;

      .description {
        margin-left: 0;
      }

      .primary-button {
        margin: 10px;
        min-width: 99px;
      }
    }
    .claim-buttons {
      display: flex;
      justify-content: center;
    }
  }
}
