body {
  .logout-open-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-container {
      width: 550px;
      height: 550px;
      background: #2e2e2e;
      border-radius: 16px;

      @media (max-width: 670px) {
        display: flex;
        width: 200px;
        height: 200px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media screen and (max-width: 1200px) {
        font-size: 25px;
        line-height: 30px;
      }
      @media screen and (max-width: 670px) {
        font-size: 20px;
        line-height: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-top: 10px;
      margin-bottom: 0;
      margin-right: 10px;
      margin-left: 10px;

      @media screen and (max-width: 1200px) {
        font-size: 30px;
        line-height: 47px;
      }
      @media screen and (max-width: 670px) {
        font-size: 4vw;
        line-height: 20px;
      }
    }

    .claimed {
      text-align: center;
      padding: 10px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 670px) {
        padding: 0px;
      }

      .description {
        margin-left: 10px;
      }

      .primary-button {
        margin: 10px;
        min-width: 99px;

        @media screen and (max-width: 670px) {
          margin: 5px;
          min-width: 77px;
        }
      }
    }
  }
}
