body {
  #root {
    .project-detail {
      margin-bottom: 75px;

      .cover-image-container {
        width: 100%;
        height: 100%;
        background: rgba(36, 38, 38, 0.47);
        border-radius: 40px;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
          height: 100%;
          border-radius: 35px;
        }

        @media screen and (max-width: 1200px) {
          height: 100%;
        }

        @media screen and (max-width: 767px) {
          height: 100%;
          border-radius: 30px;
        }

        @media screen and (max-width: 575px) {
          height: 100%;
          border-radius: 25px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .details-container {
        margin-top: -90px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1500px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          @media screen and (max-width: 767px) {
            margin-top: -65px;
          }

          @media screen and (max-width: 575px) {
            margin-top: -45px;
          }
        }

        .details {
          display: flex;
          align-items: flex-end;

          @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .avatar {
            width: 256px;
            height: 256px;
            background: #0F1010;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 45px;
            margin-right: 40px;
            margin-left: 84px;
            overflow: hidden;

            @media screen and (max-width: 1200px) {
	      width: 200px;
              height: 200px;
              margin-left: 20px;
              margin-right: 0;
            }

            @media screen and (max-width: 767px) {
              width: 150px;
              height: 150px;
              border-radius: 35px;
            }

            @media screen and (max-width: 575px) {
              width: 85px;
              height: 85px;
              border-radius: 25px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .info {
            margin-bottom: 50px;

            @media screen and (max-width: 1200px) {
              margin-left: 20px;
              margin-bottom: 0;
              margin-top: 20px;
            }

            @media screen and (max-width: 575px) {
              font-size: 23px;
            }

            h1 {
              font-size: 40px;
              font-weight: 500;
              color: #fff;
              text-transform: uppercase;

              @media screen and (max-width: 767px) {
                font-size: 28px;
              }
            }

            h4 {
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;
              color: #FF3400;
              letter-spacing: 2px;
              text-transform: uppercase;

              @media screen and (max-width: 767px) {
                font-size: 12px;
              }

              @media screen and (max-width: 575px) {
                font-size: 10px;
              }
            }
          }
        }

        .socials {
          background: rgba(36, 38, 38, 0.47);
          border-radius: 10px;
          padding: 15px;
          display: flex;
          align-items: center;
          align-self: flex-end;
          margin-bottom: 70px;

          @media screen and (max-width: 1500px) {
            align-self: flex-start;
            margin-bottom: 0;
            margin-top: 50px;
            margin-left: 84px;
          }

          @media screen and (max-width: 1200px) {
            margin-left: 20px;
            margin-top: 20px;
          }

          @media screen and (max-width: 767px) {
            margin-top: 10px;
          }

          @media screen and (max-width: 575px) {
            margin-top: 0;
          }

          img {
            margin: 0 10px;
            pointer-events: none;

            @media screen and (max-width: 767px) {
              margin: 0 5px;
              width: 25px;
            }

            @media screen and (max-width: 575px) {
              margin: 0 3px;
              width: 22px;
            }
          }
        }
      }

      .description {
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        max-width: 660px;
        margin-top: 86px;
        margin-left: 84px;

        @media screen and (max-width: 1200px) {
          margin-left: 20px;
          margin-top: 30px;
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: normal;
          max-width: 500px;
        }

        @media screen and (max-width: 575px) {
          font-size: 13px;
          max-width: 350px;
        }
      }
    }
  }
}
