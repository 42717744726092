.custom-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.custom-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191a1a;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  text-align: center;

  .store-modal-title {
    color: white !important;
    margin-top: 0;
    font-size: 1.5em;
  }
  .store-modal-message {
    color: rgb(180, 174, 174) !important;
    margin-top: 0;
  }
  .store-loader {
    margin: 10px;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0056b3;
    }
  }
}
