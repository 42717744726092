body {
  #root {
    .layout {
      height: 100vh;
      overflow: hidden;

      .app-content {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        border-left: 3px solid #202020;

        @media screen and (max-width: 991px) {
          border-left: none;
        }
      }
    }
  }
}