.sidebar-container {
  .sidebar-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0);
    transition: background-color ease-in-out 0.75s;
    display: none;

    @media screen and (max-width: 991px) {
      &.active {
        z-index: 9;
        display: block;
        background-color: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }

  .sidebar {
    height: 100vh;
    min-width: 300px;
    max-width: 300px;
    background-color: #0F0F0F;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 12;

    @media screen and (max-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: -300px;
      transition: margin-left ease-in-out 0.75s;

      &.active {
        margin-left: 0;
      }
    }

    .navbar-brand {
      padding: 0;
      margin-top: 15px;
      margin-bottom: 45px;
      margin-right: 0;

      .logo {
        display: block;
      }

      img {
        pointer-events: none;
      }
    }

    .nav-items {
      overflow: overlay;
      overflow-x: hidden;

      &::-webkit-scrollbar-track {
        background: transparent !important;
      }
    }
  }
}