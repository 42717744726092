:root {
  --gradient-shadow: linear-gradient(45deg, #ff3400, #0500ff, #ff3400);
  body {
    .view-modal {
      background-size: 180% 180%;
    }

    .item-open-modal {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 991px) {
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 767px) {
        padding: 0px 0px;
      }

      @media screen and (max-width: 575px) {
        padding: 0px 0px;
      }

      .image-container {
        width: 550px;
        height: 550px;
        background: #2e2e2e;
        border-radius: 16px;

        @media screen and (max-width: 991px) {
          align-self: center;
          width: 425px;
          height: 425px;
        }

        @media screen and (max-width: 767px) {
          width: 300px;
          height: 300px;
        }

        @media screen and (max-width: 575px) {
          width: 200px;
          height: 200px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text-container {
        @media screen and (max-width: 991px) {
          max-width: 425px;
        }

        @media screen and (max-width: 767px) {
          max-width: 300px;
        }

        @media screen and (max-width: 575px) {
          max-width: 200px;
        }

        .title {
          font-weight: 700;
          font-size: 64px;
          line-height: 77px;
          margin-bottom: 0;
          text-transform: uppercase;

          @media screen and (max-width: 991px) {
            font-size: 50px;
            line-height: normal;
          }

          @media screen and (max-width: 767px) {
            font-size: 35px;
          }

          @media screen and (max-width: 575px) {
            font-size: 25px;
          }
        }

        .description {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          margin-left: 10px;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            font-size: 20px;
            line-height: normal;
            margin-left: 5px;
          }

          @media screen and (max-width: 767px) {
            font-size: 16px;
            margin-left: 0;
          }

          @media screen and (max-width: 575px) {
            font-size: 12px;
          }
        }

        &.claimed {
          text-align: center;

          .description {
            margin-left: 0;
          }

          .primary-button {
            margin-top: 10px;
            min-width: 99px;
          }
        }
      }
    }
    .modal-dialog-centered {
      justify-content: center;
      .modal-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: auto;
        border-radius: 15px;

        .video-modal {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 15px;

          .video-container {
            display: flex;
            position: relative;
            height: 550px;
            width: 550px;
            border-radius: 15px;
            @media screen and (max-width: 991px) {
              align-self: center;
              width: 425px;
              height: 425px;
            }

            @media screen and (max-width: 767px) {
              width: 300px;
              height: 300px;
            }

            @media screen and (max-width: 575px) {
              width: 200px;
              height: 200px;
            }
          }
          .close {
            width: 100%;
            display: flex;
            position: absolute;
            z-index: 10;
            text-align: center;
            justify-content: flex-end;
            .closeX {
              display: flex;
              cursor: pointer;
              padding-right: 20px;
              padding-top: 15px;
              color: #ff330048;
            }
          }
          .video-element {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 550px;
            height: auto;
            transition: opacity 1s ease-in-out;
            border-radius: 20px;
            padding: 10px;
            -webkit-playsinline: true;
            @media screen and (max-width: 991px) {
              align-self: center;
              width: 425px;
              height: 425px;
            }

            @media screen and (max-width: 767px) {
              width: 300px;
              height: 300px;
            }

            @media screen and (max-width: 575px) {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }

    .video-hidden {
      opacity: 0;
    }
  }
  .video-title {
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure it spans the full width of the modal
    margin-bottom: 10px;
    .vidTitle {
      font-size: 3em;
      margin: 0px;
      font-weight: bold;
      text-align: left;
      padding-left: 10px;
      @media screen and (max-width: 991px) {
        max-width: 425px;
        font-size: 2.5em;
      }

      @media screen and (max-width: 767px) {
        max-width: 300px;
        font-size: 2em;
      }

      @media screen and (max-width: 575px) {
        max-width: 200px;
        font-size: 1.5em;
      }
    }
    .vidDesc {
      font-size: 1em;
      text-align: left;
      max-width: 550px;
      font-weight: 300;
      text-wrap: wrap;
      padding-left: 10px;
      padding-right: 10px;
      @media screen and (max-width: 991px) {
        max-width: 425px;
        font-size: 0.9em;
      }

      @media screen and (max-width: 767px) {
        max-width: 300px;
        font-size: 0.8em;
      }

      @media screen and (max-width: 575px) {
        max-width: 200px;
        font-size: 0.7em;
      }
    }
    @keyframes pulseShadow {
      0% {
        box-shadow: 0 0 5px 1px #ff330000;
      }
      50% {
        box-shadow: 0 0 12px 7px #ff3300a9;
      }
      100% {
        box-shadow: 0 0 5px 1px #ff330000;
      }
    }
    @keyframes animate {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 99% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
}
.video-modal:before,
.video-modal:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background: var(--gradient-shadow);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  border-radius: 15px;

  animation: animate 15s linear infinite;
  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
  }
}
.video-modal:after {
  filter: blur(20px);
  border-radius: 15px;

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
  }
}
