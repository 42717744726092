body {
  .item-open-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-container {
      max-width: 550px;
      height: auto; /* Add height:auto to allow the image to scale with the width */
      border-radius: 16px;
      background: #2e2e2e;
    }

    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: uppercase;

      @media screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }

      @media screen and (max-width: 575px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 2.4vw;
      line-height: 2.9vw;
      margin-left: 1vw;
      margin-bottom: 0;
    }

    .claimed {
      text-align: center;
      align-items: center;
      padding: 15px;

      .description {
        margin-left: 0;
      }

      .primary-button {
        margin: 10px;
        min-width: 99px;
      }

      .primary-button-two {
        display: none;
        margin: 10px;
        min-width: 99px;
      }
    }
    /* Styles for small screens */
    @media (max-width: 1200px) {
      .image-container {
        display: flex;
        align-items: center;
      }

      .title {
        font-size: 8vw;
        line-height: 10vw;
      }
      .primary-button-two {
        align-items: center;
      }

      .description {
        font-size: 3.2vw;
        line-height: 3.9vw;
        margin-left: 0;
      }

      .claimed {
        .primary-button {
          min-width: 20vw;
        }
      }
      @media (max-width: 767px) {
        .image-container {
          display: flex;
          width: 200px;
          height: 200px;
        }

        .title {
          font-size: 8vw;
          line-height: 10vw;
        }

        .description {
          font-size: 3.2vw;
          line-height: 3.9vw;
          margin-left: 0;
        }

        .claimed {
          .primary-button {
            min-width: 20vw;
          }
          .primary-button-two {
            display: inline-block;
            margin: 10px;
            min-width: 99px;
          }
        }
      }
    }
  }
}
