body {
    #root {
        .nft-card {
            background: rgba(36, 38, 38, 0.47);
            border-radius: 16px;
            padding: 9px;
            padding-bottom: 10px;
            margin: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 269px;
            overflow: hidden;
            flex: 0 1 100%;

            .image-container {
                height: 250px;
                width: 100%;
                max-width: 250px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 8px;
                margin-bottom: 15px;
                overflow: hidden;

                @media screen and (max-width: 925px) {
                    height: 175px;
                    width: 175px;
                    margin-bottom: 15px;
                }
            }

            .nft-info {
                display: flex;
                align-items: center;
                width: 100%;
                padding-bottom: 10px;
                justify-content: center;

                .title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                    margin-right: 20px;
                    padding-left: 15px;
                    white-space: wrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    align-items: center;

                    @media screen and (max-width: 670px) {
                    font-size: 14px;
                    margin-right: 0px;
                    padding-left: 0px;
                    text-align: center;
                    white-space: wrap;
                    line-height: 18px;
                    }
                }

                .loader {
                    border: 20px solid red;
                    border-top: 5px solid #3498db;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 1s linear infinite;

                    @keyframes spin {
                        0% {
                            transform: rotate(0deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }
            }

            .primary-button {
                min-width: 110px;
                font-size: 50px;
            }
        }
    }
}
}
