body {
  #root {
    .claiming-details {
      .content {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }

        .left-container {
          min-width: 100%;
          max-width: 100%;
          padding: 0 20px;

          @media screen and (max-width: 1700px) {
            min-width: 330px;
            max-width: 330px;
          }

          @media screen and (max-width: 1500px) {
            min-width: 260px;
            max-width: 260px;
          }

          @media screen and (max-width: 1200px) {
            min-width: 100%;
            max-width: 100%;
            padding: 0;
          }

          .title {
            font-weight: 400;
            font-size: 24px;
            line-height: 16px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 0;
            margin-left: 12px;
          }

          .header {
            margin-bottom: 29px;
          }

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media screen and (max-width: 1200px) {
              margin-bottom: 20px;
            }

            .description {
              font-weight: 300;
              font-size: 16px;
              line-height: 19px;
              color: #FFFFFF;

              @media screen and (max-width: 1700px) {
                font-size: 15px;
              }

              @media screen and (max-width: 1500px) {
                font-size: 14px;
              }

              @media screen and (max-width: 1200px) {
                margin-bottom: 0;
                background: rgba(36, 38, 38, 0.47);
                border-radius: 16px;
                padding: 9px;
              }
            }

            .second-section {
              @media screen and (max-width: 1200px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
              }

              @media screen and (max-width: 767px) {
                flex-direction: column;
              }

              .search-container {
                margin-top: 50px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1200px) {
                  margin-top: 20px;
                }

                .title {
                  @media screen and (max-width: 1700px) {
                    font-size: 15px;
                  }
                }

                .search-bar {
                  &.input-group {
                    background-color: #0F0F0F;
                    border: 3px solid #191A1A;
                    border-radius: 5px;
                    width: 100%;
                    max-width: 145px;

                    span {
                      background-color: #0F0F0F;
                      color: #fff;
                      border: none;

                      svg {
                        font-size: 20px;
                        color: #fff;
                        opacity: 0.1;
                      }
                    }

                    input {
                      background-color: #0F0F0F;
                      color: #fff;
                      border: none;
                      box-shadow: none !important;
                    }

                    @media screen and (max-width: 1425px) {
                      width: 250px;
                    }
                  }
                }
              }
            }
          }
        }

        .claim-items {
          display: grid;
          margin-top: 10px;
          border-top: 3px solid #202020;
          padding: 0px;
          width: 100%;
          justify-content: center;
          grid-template-columns: repeat(auto-fit, minmax(300px, 0px));

          @media screen and (max-width: 925px) {
            grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
          }

          @media screen and (max-width: 670px) {
            grid-template-columns: repeat(auto-fit, minmax(175px, 175px));
          }
          .claimings-slider {
            .nft-card {
              margin-bottom: 20px;
            }

            .slick-list {
              margin: 0 -5px;
            }

            .slick-slide {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}