body {
  #root {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #0F0F0F;
      padding-left: 88px;
      padding-right: 25px;
      padding-top: 35px;
      padding-bottom: 35px;
      position: relative;

      @media screen and (max-width: 1500px) {
        padding: 35px;
      }

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
      }

      @media screen and (max-width: 991px) {
        padding: 15px;
      }

      .menu-button {
        display: none;
        position: absolute;
        left: 10px;
        top: 20px;

        @media screen and (max-width: 991px) {
          display: block;
        }

        .secondary-button {
          min-width: 30px !important;
          width: 30px !important;
          height: 30px !important;
        }
      }

      .title {
        font-size: 48px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
        cursor: default;

        @media screen and (max-width: 1500px) {
          font-size: 35px;
        }

        @media screen and (max-width: 1200px) {
          margin-bottom: 20px;
        }

        @media screen and (max-width: 991px) {
          font-size: 30px;
        }

        @media screen and (max-width: 575px) {
          margin-top: 5px;
          font-size: 25px;
        }
      }

      .right-section {
        display: flex;
        align-items: center;

        @media screen and (max-width: 575px) {
          flex-direction: column;
        }

        .searchbar {
          margin-right: 40px;

          @media screen and (max-width: 575px) {
            margin-right: 0;
            margin-bottom: 10px;
          }

          &.input-group {
            background-color: #0F0F0F;
            border: 3px solid #191A1A;
            border-radius: 5px;
            width: 380px;

            span {
              background-color: #0F0F0F;
              color: #fff;
              border: none;

              svg {
                font-size: 20px;
                color: #fff;
                opacity: 0.1;
              }
            }

            input {
              background-color: #0F0F0F;
              color: #fff;
              border: none;
              box-shadow: none !important;
            }

            @media screen and (max-width: 1500px) {
              width: 250px;
            }
          }
        }

        .button-continer {
          display: flex;
          align-items: center;

          .primary-button {
            margin-right: 15px;

            @media screen and (max-width: 575px) {
              margin-right: 0;
            }
          }

          img {
            @media screen and (max-width: 575px) {
              display: none;
            }
          }
        }
      }
    }
  }
}