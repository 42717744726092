body {
  #root {
    .active-claim-card {
      background: rgba(36, 38, 38, 0.47);
      border-radius: 40px;
      padding: 32px;
      display: flex;
      flex-direction: row;
      width: 90%;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: center;
        padding: 15px;
      }

      .card-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1500px) {
          align-items: flex-start;
        }

        @media screen and (max-width: 767px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .card-date {
          font-weight: 400;
          font-size: 24px;
          line-height: 0px;
          color: #d64823;
          width: 100%;

          @media screen and (max-width: 1500px) {
            font-size: 25px;
            margin-bottom: 15px;
            line-height: normal;
            width: auto;
          }

          @media screen and (max-width: 1200px) {
            font-size: 17px;
            margin-bottom: 10px;
          }

          @media screen and (max-width: 767px) {
            margin: 10px 0;
          }
        }

        .card-title {
          font-weight: 700;
          font-size: 50px;
          line-height: 93px;
          color: #ffffff;
          margin-bottom: 10px;
          width: 100%;

          @media screen and (max-width: 1500px) {
            width: auto;
            font-size: 47px;
            margin-bottom: 15px;
            line-height: normal;
          }

          @media screen and (max-width: 1200px) {
            font-size: 35px;
            margin-bottom: 10px;
          }

          @media screen and (max-width: 767px) {
            font-size: 25px;
          }
        }

        .card-description {
          width: 100%;
          max-width: 475px;
          max-height: 200px;
          overflow: auto;
          font-weight: 300;
          font-size: 16px;
          line-height: 21px;
          color: #ffffff;
          margin-bottom: 50px;

          @media screen and (max-width: 1500px) {
            font-size: 14px;
            margin-bottom: 25px;
            line-height: normal;
            max-width: 320px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 13px;
            margin-bottom: 20px;
            max-width: 250px;
          }

          @media screen and (max-width: 767px) {
            text-align: center;
            margin-bottom: 30px;
          }
        }

        .card-buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;
          max-width: 475px;

          @media screen and (max-width: 1500px) {
            flex-direction: row;
            align-items: flex-start;
          }

          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
          }

          .price {
            min-width: 190px;
            height: 38px;
            border-radius: 4px;
            border: 2px solid #2c2c2c;
            background-color: transparent;
            padding: 0;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;

            @media screen and (max-width: 1500px) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .card-image-container {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 45px;
        width: 535px;
        height: 535px;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
          width: 435px;
          height: 435px;
        }

        @media screen and (max-width: 1200px) {
          width: 335px;
          height: 335px;
        }

        @media screen and (max-width: 575px) {
          width: 235px;
          height: 235px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
